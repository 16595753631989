import { useEffect } from 'react';
import './App.css';

import React, { useState } from 'react';

const checkTronWeb = () => window.tronWeb && window.tronLink;

const waitTron = () => {
  return new Promise((resolve, reject) => {
    let attempts = 0, maxAttempts = 100;
    const checkTron = () => {
      if (checkTronWeb()) {
        resolve(true);
        return 
      }
      attempts++;
      if (attempts > maxAttempts) {
        reject(false);
        return
      }
      setTimeout(checkTron, 100);
    };

    checkTron();
  });
}

const loadInfo = (contract, address) => {
  return new Promise((resolve, reject) => {
    contract.statusOf(address).call().then((result) => {
      console.log("statusOf", result);
      resolve(result);
    }).catch((err) => {
      reject(err);
    });
  });
}



const initContract = async () => {
  const contractAddress = "TUPmayJ5CWWKjYFVmML2ycKXMCbyKbgtML";
  let tronExist = await waitTron();
  if (!tronExist) {
    alert("tron not exist");
    return null;
  }

  let tronLink = await window.tronLink.request({method: 'tron_requestAccounts'});
  console.log("tronLink OK", tronLink);
  // my address
  const address = window.tronWeb.defaultAddress.base58;
  console.log("tron exist");
  let contract = await window.tronWeb.contract().at(contractAddress)
  console.log("contract ready...");
  
  return {tronweb: window.tronWeb, contract: contract, address: address};
}

function getDate(value) {
  if (value.lt(2000000000) &&
      value.gt(0)) {
    return String(new Date(value.toNumber() * 1000));
  }
  return "N/A";
}


function unfreeze(contract) {
  return async () => {
    const requestAccess = await window.tronLink.request({method: 'tron_requestAccounts'})
    console.log("requestAccess", requestAccess);
    console.log("unfreeze");
    let result = await contract.unfreeze().send();
    console.log("unfreeze result", result);
  }
}

function withdraw(contract) {
  return async () => {
    const requestAccess = await window.tronLink.request({method: 'tron_requestAccounts'})
    console.log("requestAccess", requestAccess);
    console.log("withdraw");
    let result = await contract.withdraw().send({shouldPollResponse: true});
    console.log("withdraw result", result);
  }
}

function formatAAAAMMDD(data) {
  const year = data.getFullYear();
  let month = data.getMonth() + 1; 
  if (month < 10) {
    month = '0' + month; 
  }
  let day = data.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  return `${year}${month}${day}`;
}

function addEvent(date, event , description) {
  const eventDate = new Date(date);
  eventDate.setHours(eventDate.getHours() + 1);
  const eventStartDate = formatAAAAMMDD(date); 
  const eventEndDate = formatAAAAMMDD(eventDate); 
  const eventName = event+' DVK';
  const eventDescription = description;

  const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventName}&dates=${eventStartDate}/${eventEndDate}&details=${eventDescription}&location=WebsiteURL`;
  
  window.open(url);
}

function App() {
  const [tronWeb, setTronWeb] = useState(null);
  const [address, setAddress] = useState(null);
  const [contract, setContract] = useState(null);
  const [info, setInfo] = useState(null); // [info, setInfo


  const [showDiv, setShowDiv] = useState(false);
  const toggleDiv = () => {
    setShowDiv(!showDiv);
  };


  useEffect(() => {
    initContract().then(({tronweb, contract, address}) => {
      console.log("address", address);
      setAddress(address);

      setTronWeb(tronweb);

      window.addEventListener('setAccount', () => {
        const address = tronWeb.defaultAddress.base58;
        console.log("address event listener", address);
        setAddress(address);
      });
    
      setContract(contract);
    });
  }, []);

  useEffect(() => {
    if (contract && address) {
      loadInfo(contract, address).then((result) => {
        setInfo(result);
      }).catch((err) => {
        alert("error loading info");
      });
    }
  }, [contract, address]);

  return (
    <div className="App">
      <header className="App-header">
      <img src="/embryo.png" height="120" alt="" className="embryo" />
        <img src="/DevikinsLogo_White.svg" height="48" alt="" />
        <p>Welcome to the DVK TRC20 - Unstake tool for <a href="https://chromewebstore.google.com/detail/klever-wallet/ifclboecfhkjbpmhgehodcjpciihhmif" target='_blank' rel="noreferrer"><img src="/icon-klever.png" alt="" height="20"/>Klever Wallet Extension</a> </p>


        <button onClick={toggleDiv}>Instructions <img src="/instructions.svg" height="56" alt="" /></button>
      </header>

      {!contract && <div><div className="wallet-address"><p><img src="/loading-w.gif" height="36" />Loading contract...</p></div></div>}
      {contract &&
        <div>
          

          {!address && <div><div className="wallet-address"><p><img src="/loading-w.gif" height="36" /> Loading address...</p></div></div>}

          {address && <div>
            <div className="wallet-address"><p><img src="/DVK_med.png" alt="" height="24" /><b>Your address is:</b> {address}</p></div>
            {!info && 
            
            <div className="container">

            

            <div className="row">
              <div className="card card-loading"><img src="/loading-p.gif" height="32" width="32" /> Loading info...</div>
            </div></div>  
            }
            {info &&

              <div className="container">
                
                
                <div className="fisrt-instructions">To unstake DVK, you need to unfreeze it. After unfreeze, you can withdraw 7 days later.</div>  

                <div className="row">

                  <div className="card"><b>Frozen balance:</b> {info.frozen.toNumber()} DVK</div>
                  <div className="card"><b>Unfreeze Available On:</b> 
                  
                    <div className="date">
                    
                      {getDate(info.unfreezeAvailableOn)}
                                        
                    </div>
                    
                  </div>

                </div>
                
                <div className="row row-center">

                    <h2>Step 1</h2>
                   



                  {/* Check if there is any frozen token */}
                  {info.frozen.toNumber() > 0 ? (

                    <button className="bevel-button" onClick={unfreeze(contract)} >Unfreeze</button>

                  ) : (
                    // Check if there is any unfrozen token

                    info.unfrozen.toNumber() > 0 ? (

                      <p className="success-message">Everything is fine at this step! You've already requested the unfrozen, and you just need to wait for the period to withdraw.</p>

                    ) : (

                       // No DVK found
                     <p className="warning-message">You don't have any locked balance in DVK staking.</p>
                      

                    )
                  )}


                </div>

                <div className="row">

                  <div className="card"><b>Unfrozen balance: </b>{info.unfrozen.toNumber()}</div>
                  <div className="card"><b>Available On: </b> <div className="date">
                    
                    
                    {getDate(info.availableOn)} 

                    {info.availableOn && info.availableOn.lt(2000000000) && info.availableOn.gt(0) && (
                      <div>
                        <button className="google-calendar" onClick={() => addEvent(new Date(info.availableOn.toNumber() * 1000), 'Withdraw', 'Dont forget that today your DVK will be available for withdrawal.' )}>Add to Google Calendar</button>
                      </div>
                    )}


                    
                    
                  
                  
                  </div></div>
                  
                </div>
                <div className="row row-center">
                  <h2>Step 2</h2>

                  {/* Check if there is any unfrozen token */}

                  {info.unfrozen.toNumber() > 0 ? (

                      <button className="bevel-button" onClick={withdraw(contract)}>Withdraw</button>

                    ) : (

                    // Check if there is any frozen token

                    info.frozen.toNumber() > 0 ? (

                      <p className="warning-message">Before withdrawing, you need to unfreeze your DVKs.</p>

                    ) : (

                      // No DVK found
                      <p className="warning-message">You don't have any locked balance in DVK staking.</p>

                    )
                    )}
                  
                </div>
              </div>
        
            }
            </div>
          }
        </div>
      }
      {showDiv && (
      <div className="solutions">
        <h3>Instructions</h3>
        <button onClick={toggleDiv}><img src="/close.svg" height="56" alt="" /></button>

        <div className="">
        <ol>
          <li>You'll need the Klever wallet for your browser (Extension). If you don't have it, download it through the link below:
            <p><a href="https://chromewebstore.google.com/detail/klever-wallet/ifclboecfhkjbpmhgehodcjpciihhmif">Download Klever Wallet Extension</a></p>
          </li>
          <li>When accessing the website with Klever Wallet Extension configured and your account selected, you'll see your address on the site and your frozen balance will be displayed on the screen.</li>
          <li>Next to your frozen balance, you'll see the date when your unfreezing is available. (It becomes available after 3 days of freezing, so all frozen tokens are already available to begin the process currently).</li>
          <li>Click on Unfreeze Button and sign the contract through the Klever Wallet Extensiont (You need to have available TRX balance for fee payment).</li>
          <li>Upon completing the transaction and once it's confirmed on the Blockchain, your balance will be unfrozen. It will become available for withdrawal 7 days after this unfreezing. You can add this date to your Google Calendar to remember.</li>
          <li>When the waiting period is over, simply Click the Withdraw Button  and confirm the transaction in your Klever Wallet Extension; in a few minutes, your DVKs should be available in your account. (You need to have available TRX balance for fee payment).</li>
          <li>If you are using a Klever wallet account imported via Private Key to the Klever Wallet Extension, you can bridge to the DVK token on the KDA network directly within your wallet's Swap feature. However, you can also utilize the <a href="https://voxswap.io/DVK-DVK">Vox Swap</a> website if you prefer to do so through your extension.</li>
        </ol>
        <div className="bridge-alert">
        ** We kindly ask you to complete the entire process as soon as possible as the token will soon be deactivated on the Tron network, and individuals who do not bridge by 2024-03-31 will lose their funds. **
        </div>
        </div>
      </div>
      )}
    </div>
  );
}

export default App;
